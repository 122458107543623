@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
.djacc {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
.djacc * {
  box-sizing: inherit;
}
.djacc button {
  font: inherit;
  display: inline-block;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  border: none;
  box-shadow: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
}
.djacc svg {
  vertical-align: middle;
}
.djacc ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.djacc--hidden {
  display: none;
}
.djacc--sticky {
  position: fixed;
  z-index: 999;
}
.djacc--sticky.djacc--top-left {
  top: 0;
  left: 0;
}
.djacc--sticky.djacc--top-center {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.djacc--sticky.djacc--center-left {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.djacc--sticky.djacc--center-right {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.djacc--sticky.djacc--top-right {
  top: 0;
  right: 0;
}
.djacc--sticky.djacc--bottom-left {
  bottom: 0;
  left: 0;
}
.djacc--sticky.djacc--bottom-center {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.djacc--sticky.djacc--bottom-right {
  bottom: 0;
  right: 0;
}
.djacc--static {
  position: relative;
  display: inline-flex;
  margin: 0;
}

.djacc-popup .djacc__openbtn {
  position: relative;
  display: block;
  width: 48px;
  height: 48px;
  line-height: 1;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  transition: all ease 0.2s;
}
.djacc-popup .djacc__openbtn--default {
  border-radius: 50%;
  background: #2d79ff;
}
.djacc-popup .djacc__openbtn--default:hover .djacc-popup .djacc__openbtn--default--active {
  background: #1469ff;
}
.djacc-popup .djacc__panel {
  visibility: hidden;
  pointer-events: none;
  background: #242424;
  color: #fff;
  border-radius: 40px;
  width: 468px;
  padding: 16px;
  overflow-y: auto;
  position: relative;
  z-index: 2;
}
@media (max-width: 480px) {
  .djacc-popup .djacc__panel {
    width: 90vw;
  }
}
.djacc-popup .djacc__panel--active {
  visibility: visible;
  pointer-events: all;
}
.djacc-popup .djacc__header {
  display: flex;
  align-items: center;
  margin: 0 0 25px;
}
@media (max-width: 480px) {
  .djacc-popup .djacc__header {
    margin: 0 0 15px;
  }
}
.djacc-popup .djacc__title {
  flex: 1;
  font-size: 22px;
  line-height: 29px;
  font-weight: bold;
  margin: 0;
}
.djacc-popup .djacc__reset, .djacc-popup .djacc__close {
  display: block;
  width: 48px;
  height: 48px;
  font: inherit;
  line-height: 1;
  padding: 0;
  background: transparent;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 50%;
}
.djacc-popup .djacc__reset:hover {
  background: #2F2F2F;
  border-color: #353536;
}
.djacc-popup .djacc__close {
  background: #2D79FF;
  margin-left: 10px;
}
.djacc-popup .djacc__close:hover {
  background: #1469ff;
  border-color: transparent;
}
.djacc-popup .djacc__list {
  list-style: none;
  padding: 0;
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
}
.djacc-popup .djacc__item {
  margin: 0;
  padding: 4px;
  width: 50%;
}
.djacc-popup .djacc__item--full {
  width: 100%;
}
.djacc-popup .djacc__item:before, .djacc-popup .djacc__item:after {
  display: none;
}
.djacc-popup .djacc__item:not(.djacc__item--full) + .djacc__item--full {
  margin-top: 8px;
}
.djacc-popup .djacc__btn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #2F2F2F;
  border: 1px solid #353536;
  color: inherit;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  border-radius: 6px;
  padding: 16px;
  margin: 0;
}
.djacc-popup .djacc__btn:hover {
  background: #343434;
  border-color: #2D79FF;
}
.djacc-popup .djacc__btn--active {
  background: #2D79FF;
  border-color: #2D79FF;
}
.djacc-popup .djacc__btn--active:hover {
  background: #2372ff;
}
.djacc-popup .djacc__btn svg {
  flex-shrink: 0;
  margin-right: 16px;
}
@media (max-width: 480px) {
  .djacc-popup .djacc__btn {
    padding: 10px;
    flex-direction: column;
    justify-content: center;
  }
  .djacc-popup .djacc__btn svg {
    margin: 0 auto 10px;
  }
  .djacc-popup .djacc__btn .djacc_btn-label {
    flex: 1;
  }
}
.djacc-popup .djacc__arrows {
  font: inherit;
  display: flex;
  width: 100%;
  background: #2F2F2F;
  border: 1px solid #353536;
  color: inherit;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  border-radius: 10px;
}
.djacc-popup .djacc__arrows:hover {
  background: #343434;
  border-color: #2D79FF;
}
.djacc-popup .djacc__label {
  flex: 1;
}
.djacc-popup .djacc__percent {
  opacity: 0.6;
}
.djacc-popup .djacc__size {
  align-self: center;
  width: 66px;
}
.djacc-popup .djacc__label, .djacc-popup .djacc__size {
  padding: 14px;
}
.djacc-popup .djacc__inc, .djacc-popup .djacc__dec {
  font: inherit;
  display: inline-block;
  color: inherit;
  background: transparent;
  font-size: 24px;
  line-height: 1;
  font-weight: 500;
  border: none;
  border-left: 1px solid #353536;
  border-radius: 0;
  width: 50px;
  padding: 5px;
  margin: 0;
}
.djacc-popup .djacc__inc:hover svg [fill^="#"], .djacc-popup .djacc__dec:hover svg [fill^="#"] {
  fill: #2D79FF;
}
.djacc-popup .djacc__inc:hover svg [stroke^="#"], .djacc-popup .djacc__dec:hover svg [stroke^="#"] {
  stroke: #2D79FF;
}
.djacc-popup .djacc__bar {
  display: block;
  padding: 0 12px;
  position: relative;
  width: 100%;
  max-width: 120px;
  height: 4px;
  background: #242424;
  align-self: center;
}
.djacc-popup .djacc__bar:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background: #2D79FF;
  pointer-events: none;
}
.djacc-popup .djacc__bar:after {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  height: 4px;
  width: 4px;
  background: #2D79FF;
  border: 4px solid #fff;
  border-radius: 50%;
  pointer-events: none;
}
@media (max-width: 480px) {
  .djacc-popup .djacc__bar {
    display: none;
  }
}
.djacc-popup .djacc__arrows[data-djacc-count] .djacc__bar:before {
  width: 100%;
}
.djacc-popup .djacc__arrows[data-djacc-count="0"] .djacc__bar:before {
  width: 50%;
}
.djacc-popup .djacc__arrows[data-djacc-count="1"] .djacc__bar:before {
  width: 55%;
}
.djacc-popup .djacc__arrows[data-djacc-count="2"] .djacc__bar:before {
  width: 60%;
}
.djacc-popup .djacc__arrows[data-djacc-count="3"] .djacc__bar:before {
  width: 65%;
}
.djacc-popup .djacc__arrows[data-djacc-count="4"] .djacc__bar:before {
  width: 70%;
}
.djacc-popup .djacc__arrows[data-djacc-count="5"] .djacc__bar:before {
  width: 75%;
}
.djacc-popup .djacc__arrows[data-djacc-count="6"] .djacc__bar:before {
  width: 80%;
}
.djacc-popup .djacc__arrows[data-djacc-count="7"] .djacc__bar:before {
  width: 85%;
}
.djacc-popup .djacc__arrows[data-djacc-count="8"] .djacc__bar:before {
  width: 90%;
}
.djacc-popup .djacc__arrows[data-djacc-count="9"] .djacc__bar:before {
  width: 95%;
}
.djacc-popup .djacc__arrows[data-djacc-count^="-"] .djacc__bar:before {
  width: 0%;
}
.djacc-popup .djacc__arrows[data-djacc-count="-1"] .djacc__bar:before {
  width: 45%;
}
.djacc-popup .djacc__arrows[data-djacc-count="-2"] .djacc__bar:before {
  width: 40%;
}
.djacc-popup .djacc__arrows[data-djacc-count="-3"] .djacc__bar:before {
  width: 35%;
}
.djacc-popup .djacc__arrows[data-djacc-count="-4"] .djacc__bar:before {
  width: 30%;
}
.djacc-popup .djacc__arrows[data-djacc-count="-5"] .djacc__bar:before {
  width: 25%;
}
.djacc-popup .djacc__arrows[data-djacc-count="-6"] .djacc__bar:before {
  width: 20%;
}
.djacc-popup .djacc__arrows[data-djacc-count="-7"] .djacc__bar:before {
  width: 15%;
}
.djacc-popup .djacc__arrows[data-djacc-count="-8"] .djacc__bar:before {
  width: 10%;
}
.djacc-popup .djacc__arrows[data-djacc-count="-9"] .djacc__bar:before {
  width: 5%;
}
.djacc-popup .djacc__arrows[data-djacc-count] .djacc__bar:after {
  left: 100%;
}
.djacc-popup .djacc__arrows[data-djacc-count="0"] .djacc__bar:after {
  left: 50%;
}
.djacc-popup .djacc__arrows[data-djacc-count="1"] .djacc__bar:after {
  left: 55%;
}
.djacc-popup .djacc__arrows[data-djacc-count="2"] .djacc__bar:after {
  left: 60%;
}
.djacc-popup .djacc__arrows[data-djacc-count="3"] .djacc__bar:after {
  left: 65%;
}
.djacc-popup .djacc__arrows[data-djacc-count="4"] .djacc__bar:after {
  left: 70%;
}
.djacc-popup .djacc__arrows[data-djacc-count="5"] .djacc__bar:after {
  left: 75%;
}
.djacc-popup .djacc__arrows[data-djacc-count="6"] .djacc__bar:after {
  left: 80%;
}
.djacc-popup .djacc__arrows[data-djacc-count="7"] .djacc__bar:after {
  left: 85%;
}
.djacc-popup .djacc__arrows[data-djacc-count="8"] .djacc__bar:after {
  left: 90%;
}
.djacc-popup .djacc__arrows[data-djacc-count="9"] .djacc__bar:after {
  left: 95%;
}
.djacc-popup .djacc__arrows[data-djacc-count^="-"] .djacc__bar:after {
  left: 0%;
}
.djacc-popup .djacc__arrows[data-djacc-count="-1"] .djacc__bar:after {
  left: 45%;
}
.djacc-popup .djacc__arrows[data-djacc-count="-2"] .djacc__bar:after {
  left: 40%;
}
.djacc-popup .djacc__arrows[data-djacc-count="-3"] .djacc__bar:after {
  left: 35%;
}
.djacc-popup .djacc__arrows[data-djacc-count="-4"] .djacc__bar:after {
  left: 30%;
}
.djacc-popup .djacc__arrows[data-djacc-count="-5"] .djacc__bar:after {
  left: 25%;
}
.djacc-popup .djacc__arrows[data-djacc-count="-6"] .djacc__bar:after {
  left: 20%;
}
.djacc-popup .djacc__arrows[data-djacc-count="-7"] .djacc__bar:after {
  left: 15%;
}
.djacc-popup .djacc__arrows[data-djacc-count="-8"] .djacc__bar:after {
  left: 10%;
}
.djacc-popup .djacc__arrows[data-djacc-count="-9"] .djacc__bar:after {
  left: 5%;
}

.djacc-popup.djacc--top-left .djacc__panel, .djacc-popup.djacc--top-right.djacc--direction .djacc__panel {
  position: absolute;
  top: 0;
  left: 0;
}
.djacc-popup.djacc--top-center .djacc__panel {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.djacc-popup.djacc--top-right .djacc__panel, .djacc-popup.djacc--top-left.djacc--direction .djacc__panel {
  position: absolute;
  top: 0;
  right: 0;
}
.djacc-popup.djacc--center-left .djacc__panel {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
}
.djacc-popup.djacc--center-right .djacc__panel {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-50%);
}
.djacc-popup.djacc--bottom-left .djacc__panel, .djacc-popup.djacc--bottom-right.djacc--direction .djacc__panel {
  position: absolute;
  bottom: 0;
  left: 0;
}
.djacc-popup.djacc--bottom-center .djacc__panel {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.djacc-popup.djacc--bottom-right .djacc__panel, .djacc-popup.djacc--bottom-left.djacc--direction .djacc__panel {
  position: absolute;
  bottom: 0;
  right: 0;
}

.djacc-popup.djacc--top-left:hover .djacc__openbtn, .djacc-popup.djacc--top-right:hover .djacc__openbtn, .djacc-popup.djacc--bottom-left:hover .djacc__openbtn, .djacc-popup.djacc--bottom-right:hover .djacc__openbtn, .djacc-opened .djacc-popup.djacc--top-left .djacc__openbtn, .djacc-opened .djacc-popup.djacc--top-right .djacc__openbtn, .djacc-opened .djacc-popup.djacc--bottom-left .djacc__openbtn, .djacc-opened .djacc-popup.djacc--bottom-right .djacc__openbtn {
  margin: 16px;
}
.djacc-popup.djacc--center-left:hover .djacc__openbtn, .djacc-popup.djacc--center-right:hover .djacc__openbtn, .djacc-opened .djacc-popup.djacc--center-left .djacc__openbtn, .djacc-opened .djacc-popup.djacc--center-right .djacc__openbtn {
  margin: 0 16px;
}
.djacc-popup.djacc--top-center:hover .djacc__openbtn, .djacc-popup.djacc--bottom-center:hover .djacc__openbtn, .djacc-opened .djacc-popup.djacc--top-center .djacc__openbtn, .djacc-opened .djacc-popup.djacc--bottom-center .djacc__openbtn {
  margin: 16px 0;
}
.djacc-popup.djacc--static .djacc__openbtn, .djacc-popup.djacc--static:hover .djacc__openbtn, .djacc-opened .djacc-popup.djacc--static .djacc__openbtn {
  margin: 16px;
}

.admin-bar .djacc--sticky[class*=djacc--top] {
  top: 32px;
}

.djacc-popup.djacc--light .djacc__panel {
  background: #fff;
  color: #404042;
  box-shadow: 0 3px 25px rgba(0, 0, 0, 0.05);
}
.djacc-popup.djacc--light .djacc__reset svg [fill^="#"] {
  fill: #404042;
}
.djacc-popup.djacc--light .djacc__reset svg [stroke^="#"] {
  stroke: #404042;
}
.djacc-popup.djacc--light .djacc__reset:hover, .djacc-popup.djacc--light .djacc__reset:focus {
  background: #F8F9FC;
  border-color: #EBECF1;
}
.djacc-popup.djacc--light .djacc__btn,
.djacc-popup.djacc--light .djacc__arrows {
  background: #F8F9FC;
  border-color: #EBECF1;
}
.djacc-popup.djacc--light .djacc__btn:hover,
.djacc-popup.djacc--light .djacc__arrows:hover {
  background: #f1f3f9;
  border-color: #2D79FF;
}
.djacc-popup.djacc--light .djacc__btn svg [fill^="#"],
.djacc-popup.djacc--light .djacc__arrows svg [fill^="#"] {
  fill: #404042;
}
.djacc-popup.djacc--light .djacc__btn svg [stroke^="#"],
.djacc-popup.djacc--light .djacc__arrows svg [stroke^="#"] {
  stroke: #404042;
}
.djacc-popup.djacc--light .djacc__btn--active {
  color: #fff;
  background: #2D79FF;
  border-color: #2D79FF;
}
.djacc-popup.djacc--light .djacc__btn--active:hover {
  background: #2372ff;
}
.djacc-popup.djacc--light .djacc__btn--active svg [fill^="#"] {
  fill: #fff;
}
.djacc-popup.djacc--light .djacc__btn--active svg [stroke^="#"] {
  stroke: #fff;
}
.djacc-popup.djacc--light .djacc__inc,
.djacc-popup.djacc--light .djacc__dec {
  border-color: #EBECF1;
}
.djacc-popup.djacc--light .djacc__inc svg [fill^="#"],
.djacc-popup.djacc--light .djacc__dec svg [fill^="#"] {
  fill: #A1A1A2;
}
.djacc-popup.djacc--light .djacc__inc svg [stroke^="#"],
.djacc-popup.djacc--light .djacc__dec svg [stroke^="#"] {
  stroke: #A1A1A2;
}
.djacc-popup.djacc--light .djacc__inc:hover svg [fill^="#"],
.djacc-popup.djacc--light .djacc__dec:hover svg [fill^="#"] {
  fill: #2D79FF;
}
.djacc-popup.djacc--light .djacc__inc:hover svg [stroke^="#"],
.djacc-popup.djacc--light .djacc__dec:hover svg [stroke^="#"] {
  stroke: #2D79FF;
}
.djacc-popup.djacc--light .djacc__bar {
  background: #EBECF1;
}

.djacc-toolbar {
  background: #2F2F2F;
  color: #fff;
  border: 1px solid #353536;
}
.djacc-toolbar .djacc__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.djacc-toolbar .djacc__item {
  margin-top: -1px;
  margin-bottom: -1px;
}
.djacc-toolbar .djacc__item + .djacc__item {
  margin-left: -1px;
}
.djacc-toolbar .djacc__item--label {
  padding-left: 16px;
  padding-right: 16px;
}
.djacc-toolbar .djacc__item + .djacc__item--label {
  padding-left: 32px;
}
.djacc-toolbar .djacc__btn, .djacc-toolbar .djacc__reset {
  height: 40px;
  min-width: 40px;
  padding: 0 10px;
  background: #2F2F2F;
  color: inherit;
  border: 1px solid #353536;
  position: relative;
}
.djacc-toolbar .djacc__btn:hover, .djacc-toolbar .djacc__reset:hover {
  background: #2D79FF;
  border-color: #2D79FF;
  z-index: 1;
}
.djacc-toolbar .djacc__btn:hover .djacc__title, .djacc-toolbar .djacc__reset:hover .djacc__title {
  display: inline-block;
}
.djacc-toolbar .djacc__btn--active, .djacc-toolbar .djacc__reset--active {
  background: #2D79FF;
  border-color: #2D79FF;
}
.djacc-toolbar .djacc__btn--active:hover, .djacc-toolbar .djacc__reset--active:hover {
  background: #2372ff;
}
.djacc-toolbar .djacc__arrows {
  display: flex;
  align-items: center;
  height: 40px;
  min-width: 40px;
  padding: 0 10px;
  color: inherit;
  background: #2F2F2F;
  border: 1px solid #353536;
  cursor: pointer;
  position: relative;
}
.djacc-toolbar .djacc__arrows:hover {
  background: #343434;
  border-color: #2D79FF;
  z-index: 1;
}
.djacc-toolbar .djacc__arrows:hover .djacc__title {
  display: inline-block;
}
.djacc-toolbar .djacc__title {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1;
  background: #404042;
  color: #fff;
  border: 1px solid #707070;
  white-space: nowrap;
  margin: 10px 0 0;
  z-index: 2;
}
.djacc-toolbar .djacc__title:before {
  content: "";
  position: absolute;
  top: -5px;
  left: 50%;
  margin-left: -5px;
  transform: rotate(-45deg);
  display: block;
  width: 10px;
  height: 10px;
  background: #404042;
  z-index: -1;
}
.djacc-toolbar .djacc__inc, .djacc-toolbar .djacc__dec {
  background: transparent;
  padding: 0 10px;
}
.djacc-toolbar .djacc__inc:hover svg [fill^="#"], .djacc-toolbar .djacc__dec:hover svg [fill^="#"] {
  fill: #2D79FF;
}
.djacc-toolbar .djacc__inc:hover svg [stroke^="#"], .djacc-toolbar .djacc__dec:hover svg [stroke^="#"] {
  stroke: #2D79FF;
}
.djacc-toolbar svg [fill^="#"] {
  fill: #fff;
}
.djacc-toolbar svg [stroke^="#"] {
  stroke: #fff;
}
.djacc-toolbar.djacc--top-center, .djacc-toolbar.djacc--bottom-center {
  width: 100%;
}
.djacc-toolbar.djacc--center-left, .djacc-toolbar.djacc--center-right {
  display: flex;
  align-items: center;
}
@media (max-height: 800px) {
  .djacc-toolbar.djacc--center-left, .djacc-toolbar.djacc--center-right {
    top: 0;
    transform: none;
    margin: 0;
  }
}
.djacc-toolbar.djacc--center-left .djacc__list, .djacc-toolbar.djacc--center-right .djacc__list {
  flex-direction: column;
  max-height: 100vh;
}
.djacc-toolbar.djacc--center-left .djacc__item, .djacc-toolbar.djacc--center-right .djacc__item {
  width: 40px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -1px;
  margin-right: -1px;
}
.djacc-toolbar.djacc--center-left .djacc__item + .djacc__item, .djacc-toolbar.djacc--center-right .djacc__item + .djacc__item {
  margin-top: -1px;
}
.djacc-toolbar.djacc--center-left .djacc__arrows, .djacc-toolbar.djacc--center-right .djacc__arrows {
  height: auto;
  flex-direction: column;
}
.djacc-toolbar.djacc--center-left .djacc__item--label, .djacc-toolbar.djacc--center-right .djacc__item--label {
  display: none;
}
.djacc-toolbar.djacc--center-left .djacc__inc,
.djacc-toolbar.djacc--center-left .djacc__dec, .djacc-toolbar.djacc--center-right .djacc__inc,
.djacc-toolbar.djacc--center-right .djacc__dec {
  padding: 10px 0;
}
.djacc-toolbar.djacc--center-left .djacc__inc:hover svg [fill^="#"],
.djacc-toolbar.djacc--center-left .djacc__dec:hover svg [fill^="#"], .djacc-toolbar.djacc--center-right .djacc__inc:hover svg [fill^="#"],
.djacc-toolbar.djacc--center-right .djacc__dec:hover svg [fill^="#"] {
  fill: #2D79FF;
}
.djacc-toolbar.djacc--center-left .djacc__inc:hover svg [stroke^="#"],
.djacc-toolbar.djacc--center-left .djacc__dec:hover svg [stroke^="#"], .djacc-toolbar.djacc--center-right .djacc__inc:hover svg [stroke^="#"],
.djacc-toolbar.djacc--center-right .djacc__dec:hover svg [stroke^="#"] {
  stroke: #2D79FF;
}
.djacc-toolbar.djacc--bottom-left .djacc__title, .djacc-toolbar.djacc--bottom-center .djacc__title, .djacc-toolbar.djacc--bottom-right .djacc__title {
  top: auto;
  bottom: 100%;
  margin: 0 0 10px;
}
.djacc-toolbar.djacc--bottom-left .djacc__title:before, .djacc-toolbar.djacc--bottom-center .djacc__title:before, .djacc-toolbar.djacc--bottom-right .djacc__title:before {
  top: auto;
  bottom: -5px;
}
.djacc-toolbar.djacc--center-left .djacc__title {
  top: 50%;
  left: 100%;
  right: auto;
  transform: translateY(-50%);
  margin: 0 0 0 10px;
}
.djacc-toolbar.djacc--center-left .djacc__title:before {
  top: 50%;
  left: -5px;
  right: auto;
  margin: 0;
  margin-top: -5px;
}
.djacc-toolbar.djacc--center-right .djacc__title {
  top: 50%;
  left: auto;
  right: 100%;
  transform: translateY(-50%);
  margin: 0 10px 0 0;
}
.djacc-toolbar.djacc--center-right .djacc__title:before {
  top: 50%;
  left: auto;
  right: -5px;
  margin: 0;
  margin-top: -5px;
}

.djacc-toolbar.djacc--light {
  background: #F8F9FC;
  color: #404042;
  border: 1px solid #EBECF1;
}
.djacc-toolbar.djacc--light .djacc__btn,
.djacc-toolbar.djacc--light .djacc__reset {
  background: #F8F9FC;
  border: 1px solid #EBECF1;
}
.djacc-toolbar.djacc--light .djacc__btn:hover, .djacc-toolbar.djacc--light .djacc__btn--active,
.djacc-toolbar.djacc--light .djacc__reset:hover,
.djacc-toolbar.djacc--light .djacc__reset--active {
  background: #2D79FF;
  border-color: #2D79FF;
  color: #fff;
}
.djacc-toolbar.djacc--light .djacc__btn:hover svg [fill^="#"], .djacc-toolbar.djacc--light .djacc__btn--active svg [fill^="#"],
.djacc-toolbar.djacc--light .djacc__reset:hover svg [fill^="#"],
.djacc-toolbar.djacc--light .djacc__reset--active svg [fill^="#"] {
  fill: #fff;
}
.djacc-toolbar.djacc--light .djacc__btn:hover svg [stroke^="#"], .djacc-toolbar.djacc--light .djacc__btn--active svg [stroke^="#"],
.djacc-toolbar.djacc--light .djacc__reset:hover svg [stroke^="#"],
.djacc-toolbar.djacc--light .djacc__reset--active svg [stroke^="#"] {
  stroke: #fff;
}
.djacc-toolbar.djacc--light .djacc__arrows {
  background: #F8F9FC;
  border: 1px solid #EBECF1;
}
.djacc-toolbar.djacc--light .djacc__arrows:hover {
  background: white;
  border-color: #2D79FF;
}
.djacc-toolbar.djacc--light .djacc__inc svg [fill^="#"],
.djacc-toolbar.djacc--light .djacc__dec svg [fill^="#"] {
  fill: #A1A1A2;
}
.djacc-toolbar.djacc--light .djacc__inc svg [stroke^="#"],
.djacc-toolbar.djacc--light .djacc__dec svg [stroke^="#"] {
  stroke: #A1A1A2;
}
.djacc-toolbar.djacc--light .djacc__inc:hover svg [fill^="#"],
.djacc-toolbar.djacc--light .djacc__dec:hover svg [fill^="#"] {
  fill: #2D79FF;
}
.djacc-toolbar.djacc--light .djacc__inc:hover svg [stroke^="#"],
.djacc-toolbar.djacc--light .djacc__dec:hover svg [stroke^="#"] {
  stroke: #2D79FF;
}
.djacc-toolbar.djacc--light svg [fill^="#"] {
  fill: #404042;
}
.djacc-toolbar.djacc--light svg [stroke^="#"] {
  stroke: #404042;
}

.djacc-dark-contrast .djacc-contrast,
.djacc-light-contrast .djacc-contrast {
  background: none !important;
  box-shadow: none !important;
  text-shadow: none !important;
}

.djacc-dark-contrast .djacc-contrast {
  background-color: #000 !important;
  border-color: #fff !important;
  color: #fff !important;
}
.djacc-dark-contrast h1.djacc-contrast, .djacc-dark-contrast h2.djacc-contrast, .djacc-dark-contrast h3.djacc-contrast, .djacc-dark-contrast h4.djacc-contrast, .djacc-dark-contrast h5.djacc-contrast {
  color: #50d0a0 !important;
}
.djacc-dark-contrast a.djacc-contrast {
  color: #fcff3c !important;
  text-decoration: underline !important;
}
.djacc-dark-contrast a.djacc-contrast:hover, .djacc-dark-contrast a.djacc-contrast:focus {
  text-decoration: none !important;
}
.djacc-dark-contrast button.djacc-contrast,
.djacc-dark-contrast input[type=button].djacc-contrast {
  background-color: #fff !important;
  border-color: #fff !important;
  color: #000 !important;
}
.djacc-dark-contrast button.djacc-contrast:hover, .djacc-dark-contrast button.djacc-contrast:focus,
.djacc-dark-contrast input[type=button].djacc-contrast:hover,
.djacc-dark-contrast input[type=button].djacc-contrast:focus {
  background-color: #000 !important;
  border-color: #fff !important;
  color: #fff !important;
}

.djacc-light-contrast .djacc-contrast {
  background-color: #fff !important;
  border-color: #000 !important;
  color: #000 !important;
}
.djacc-light-contrast h1.djacc-contrast, .djacc-light-contrast h2.djacc-contrast, .djacc-light-contrast h3.djacc-contrast, .djacc-light-contrast h4.djacc-contrast, .djacc-light-contrast h5.djacc-contrast {
  color: #0000d3 !important;
}
.djacc-light-contrast a.djacc-contrast {
  color: #0000d3 !important;
  text-decoration: underline !important;
}
.djacc-light-contrast a.djacc-contrast:hover, .djacc-light-contrast a.djacc-contrast:focus {
  text-decoration: none !important;
}
.djacc-light-contrast button.djacc-contrast,
.djacc-light-contrast input[type=button].djacc-contrast {
  background-color: #000 !important;
  border-color: #000 !important;
  color: #fff !important;
}
.djacc-light-contrast button.djacc-contrast:hover, .djacc-light-contrast button.djacc-contrast:focus,
.djacc-light-contrast input[type=button].djacc-contrast:hover,
.djacc-light-contrast input[type=button].djacc-contrast:focus {
  background-color: #fff !important;
  border-color: #000 !important;
  color: #000 !important;
}

.djacc-highlight-links .djacc-link {
  outline: 2px solid #ff7216 !important;
  outline-offset: 2px !important;
}

.djacc-highlight-titles .djacc-title {
  outline: 2px solid #639af9 !important;
  outline-offset: 2px !important;
}

.djacc-read-mode body {
  padding: 50px !important;
  background: #f7fcff !important;
  max-width: 600px !important;
  margin: auto !important;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 15px !important;
  color: #222 !important;
  line-height: 1.5 !important;
}

.djacc-reader {
  outline: 2px solid #fdf901 !important;
  outline-offset: 2px !important;
}

*:focus-visible {
  outline: 2px solid #e0600b !important;
  outline-offset: -2px !important;
}